import React, {useEffect, useMemo, useState} from "react";
import InnerTable from "../inner-table";
import CommandCenterTableStyles from "../command-center-table-styles";
import moment from "moment/moment";
import FleetShareTableMap from "./fleet-share-table-map";
import ModalDefault from "../../modal/modal-default";
import NoRecords from "../../no-records-found/no-records";
import {Loader} from "../../loader";
import TableCard from "../../resource-table/table-components/table-card";
import {getUserDateTimeFormat, toFrontDateTime} from "../../../util/util-dates";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {fillFieldsFromData} from "../../../util/util-fields";
import {DEFAULT_DATABASE_DATE_FORMAT} from "../../../util/util-consts";
import {classNames, openInNewTab} from "../../../util/util-helpers";
import {BarsArrowDownIcon, BarsArrowUpIcon} from "@heroicons/react/20/solid";
import FieldsToHtml from "../../fields/fields-to-html"

export default function FleetShareTable({list, comments, isLoading, translate}) {
    const [data, setData] = useState([]);
    const [tableStyles, setTableStyles] = useState({});

    // const [selectedItem, setSelectedItem] = useState({});

    const fields = useMemo(() => getFields(), []);
    const [queryFields, setQueryFields] = useState(() => getQueryFields());
    const tableOptions = useMemo(() => getDefaultTableOptions(), []);
    const [sort, setSort] = useState("ASC");
    const [sortBy, setSortBy] = useState("Driver");

    const [isMapModalOpen, setIsMapModalOpen] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({});
    const hasData = !!data?.[0]?.driver?.length && !isLoading;

    const dates = useMemo(() => {
        let starDate = moment();
        let numberOfDays = 14;

        return {
            list: new Array(numberOfDays).fill(undefined).map((date, i) => starDate.clone().add(i, "days").format(DEFAULT_DATABASE_DATE_FORMAT)),
            starDate: starDate
        };
    }, [])

    function handleQueryChange(name, value) {
        let queryFieldsUpdate = Object.assign({}, queryFields);
        if ("GroupBy" === name && "0" === value) {
            value = "";
        }
        queryFieldsUpdate = FieldsManager.updateField(queryFieldsUpdate, name, value);
        setQueryFields(queryFieldsUpdate);
    }

    function handleSort(columnName) {
        if (sortBy === columnName) {
            setSort(sort === "ASC" ? "DESC" : "ASC");
        } else {
            setSort("ASC");
            setSortBy(columnName);
        }
    }

    function sortData(dataList = null, sort = 'ASC') {
        if (!dataList) {
            return [];
        }

        let modifiedData = dataList;

        if (queryFields.query.value) {
            modifiedData = dataList.filter(it => {
                return it.Driver.toLowerCase().includes(queryFields.query.value.toLowerCase()) || (it?.Truck?.toLowerCase() ?? "").includes(queryFields.query.value.toLowerCase())
            });
        }

        modifiedData.map(driver => {
            driver.Totals = Object.keys(driver?.Schedule).reduce((memo) => {
                if (!memo.PriceTotal) {
                    memo.PriceTotal = 0;
                }

                if (!memo.CalculationPriceTotal) {
                    memo.CalculationPriceTotal = 0;
                }

                if (!memo.TotalMilesTotal) {
                    memo.TotalMilesTotal = 0;
                }

                return memo;
            }, {})
            return driver;
        });

        modifiedData
            .sort((A, B) => {
                let a = A[sortBy];
                let b = B[sortBy];

                if (a === null && b !== null) {
                    return sort === "ASC" ? 1 : -1;
                } else if (a !== null && b === null) {
                    return sort === "ASC" ? -1 : 1;
                } else {
                    return sort === "ASC" ? (a === b ? 0 : (a < b ? -1 : 1)) : (a === b ? 0 : (a > b ? -1 : 1));
                }
            });

        return [{
            Dispatcher: "",
            key: "0",
            ContactGroup: "",
            "driver": modifiedData
        }]
    }


    function getQueryFields(item = {}) {
        const fieldTemplates = {
            query: new Field('query', '', [''], false, 'search', {
                hideLabel: true,
                labelType: "float",
            }, {}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    }

    function getFields() {
        return {
            Driver: new Field('Driver', '', [''], false, "custom", {
                canSort: true,
                isSticky: true,
                width: 150
            }),
            Truck: new Field('Truck', '', [''], false, "text", {
                canSort: true,
                isSticky: true,
                width: 75,
                render: (it) => <div>
                    <div>{it.Truck}</div>
                    {it.TrailerType ? (<div> ({it.TrailerType})</div>) : ''}
                </div>
            }),
            // LastLocation: new Field('LastLocation', '', [''], false, "custom", {
            //     label: "Last GPS/ELD location",
            //     canSort: false,
            //     isSticky: false,
            //     width: 170,
            //     render: (it) => {
            //         const hasCoordinates = it.Latitude && it.Longitude;
            //         return <button
            //             disabled={!hasCoordinates}
            //             onClick={() => (hasCoordinates ? handleLocationClick(it) : null)}
            //             className={
            //                 classNames(
            //                     hasCoordinates
            //                         ? "group h-full w-full flex justify-center flex-col overflow-y-auto rounded-lg bg-purple-500/20 hover:bg-purple-800/40 py-1 px-2 text-xs leading-5"
            //                         : undefined
            //                 )}
            //         >
            //             {it?.CityName || it?.State || it?.PostalCode ?
            //                 <div>{it?.CityName ? it.CityName + ", " : ""} {it?.State} {it?.PostalCode}</div> : ""}
            //             {(it.LocationDate ? <div>({toFrontDateTime(it.LocationDate)})</div> : undefined)}
            //         </button>
            //     }
            // })
        }
    }


    function getSchedulerFields(dates) {
        return dates.list.reduce((memo, it, i) => {
            const currentDay = dates.starDate.clone().add(i, "days");
            memo[it] = new Field(it, '', [''], false, "custom", {
                label: <div className="text-center leading-4 text-xs group">
                    <div
                        className="font-light"
                    >
                        {currentDay.format(getUserDateTimeFormat()?.split("/")?.slice(0, 2)?.join("/"))}
                    </div>

                    <div>{currentDay.format('dddd')}</div>
                </div>,
                width: "200",
                render: (data) => <SchedulerData date={it} data={data}/>
            })
            return memo;
        }, {});
    }

    function stripTimeFromDate(date) {
        if (!date) {
            return "";
        }

        date = toFrontDateTime(date).split(" ");
        date.splice(0, 1);

        return date;
    }

    function SchedulerData({date, data}) {
        const compressedView = tableOptions?.compressedView ?? 0
        const dateData = data?.["Schedule"]?.[date];
        if (Array.isArray(dateData)) {
            return <div
                className="flex flex-col gap-1">{dateData.map(it => processDateData(it, date, compressedView))}</div>
        } else {
            return processDateData(dateData, date);
        }
    }

    function handleCellClick(dateData, date) {
        // setIsFieldsDateDirty(false);
        // const dateDataUpdate = Object.assign({}, dateData);
        // if (dateDataUpdate?.Load) {
        //     dateDataUpdate.Load.Pickup = <div className="flex flex-col">
        //         <div>{dateData.Load.PickupCityName + ", " + dateData.Load.PickupState + " " + dateData.Load.PickupPostalCode}</div>
        //         <div className="font-normal">{toFrontDateTime(dateData.Load.PickupDate)}</div>
        //     </div>
        //     dateDataUpdate.Load.Destination = <div className="flex flex-col">
        //         <div>{dateData.Load.DestinationCityName + ", " + dateData.Load.DestinationState + " " + dateData.Load.DestinationPostalCode}</div>
        //         <div className="font-normal">{toFrontDateTime(dateData.Load.DestinationDate)}</div>
        //     </div>
        // }
        // setSelectedItem({dateData: dateDataUpdate, date});
    }

    // function toggleAutoRefresh() {
    //     if (isAutoRefreshEnabled) {
    //         setIsAutoRefreshEnabled(false);
    //         clearInterval(autoRefreshInterval.current);
    //     } else {
    //         setIsAutoRefreshEnabled(true);
    //         autoRefreshInterval.current = setInterval(() => {
    //             onFetchUpdateData();
    //         }, 60000)
    //     }
    // }

    function processDateData(dateData, date, compressedView = false) {
        switch (dateData?.Type) {
            case 'PARKED':
                return <div
                    className="group flex text-left h-full w-full items-center justify-center rounded-sm bg-yellow-500/50 py-0.5 px-2 text-xs leading-4">
                    Parked
                    - {compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </div>
            case 'TIME_OFF':
                return <div
                    className="group h-full w-full flex text-left items-center justify-start rounded-sm bg-yellow-500/50 py-0.5 px-2 text-xs leading-4">
                    Time off
                </div>
            case 'PICKED':
                return <button
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={() => null}
                    disabled={true}
                    className="group h-full w-full flex text-left flex-col justify-start rounded-sm bg-purple-500/20 hover:bg-purple-800/40 py-0.5 px-2 text-xs leading-4 disabled:cursor-default disabled:bg-purple-500/20">
                    {!compressedView ? (<>Picked up
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </button>
            case 'IN_TRANSIT_COMPLETED':
                return <button
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={() => null}
                    disabled={true}
                    className="group flex text-left h-full w-full items-center justify-start rounded-sm bg-purple-500/20 hover:bg-purple-800/40 py-0.5 px-2 text-xs leading-4 disabled:cursor-default disabled:bg-purple-500/20">
                    <span>In Transit #{dateData?.LoadNumber}</span>
                </button>
            case 'DELIVERED': {
                return <button
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={() => null}
                    disabled={true}
                    className="group h-full w-full flex text-left flex-col justify-center rounded-sm bg-purple-500/20 hover:bg-purple-800/40 py-0.5 px-2 text-xs leading-4 disabled:cursor-default disabled:bg-purple-500/20">
                    {!compressedView ? (<>Delivered
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </button>
            }
            case 'PICKUP_IN_PROGRESS':
                return <button
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={() => null}
                    disabled={true}
                    className="group flex h-full w-full text-left items-center justify-center rounded-sm bg-green-500/20 hover:bg-green-500/40 py-0.5 px-2 text-xs leading-4 disabled:cursor-default disabled:bg-green-500/20">
                    {!compressedView ? (<>Picking up
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </button>
            case 'IN_TRANSIT':
                return <button
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={() => null}
                    disabled={true}
                    className="group flex h-full w-full text-left items-center justify-start rounded-sm bg-green-500/20 hover:bg-green-500/40 py-0.5 px-2 text-xs leading-4 disabled:cursor-default disabled:bg-green-500/20">
                    <span>In Transit #{dateData?.LoadNumber}</span>
                </button>
            case 'DELIVERY_IN_PROGRESS': {
                const ETA = stripTimeFromDate(dateData?.ETATime);
                return <button
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={() => null}
                    disabled={true}
                    className="group h-full w-full flex text-left flex-col justify-center rounded-sm bg-green-500/20 hover:bg-green-500/40 py-0.5 px-2 text-xs leading-4 disabled:cursor-default disabled:bg-green-500/20">
                    {!compressedView ? (<>Delivering
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`} {ETA ? "(ETA " + stripTimeFromDate(dateData?.ETATime) + ")" : ""}
                </button>
            }
            case 'PICKUP_PLANNED':
                return <button
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={() => null}
                    disabled={true}
                    className="group h-full w-full flex text-left flex-col justify-center rounded-sm bg-blue-500/20 hover:bg-blue-500/40 py-0.5 px-2 text-xs leading-4 disabled:cursor-default disabled:bg-blue-500/20">
                    {!compressedView ? (<>Pickup planned
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </button>
            case 'IN_TRANSIT_PLANNED':
                return <button
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={() => null}
                    disabled={true}
                    className="group flex h-full w-full text-left flex-col justify-center rounded-sm bg-blue-500/20 hover:bg-blue-500/40 py-0.5 px-2 text-xs leading-4 disabled:cursor-default disabled:bg-blue-500/20">
                    In Transit #{dateData?.LoadNumber}
                </button>
            case 'DELIVERY_PLANNED':
                return <button
                    onClick={() => handleCellClick(dateData, date)}
                    onMouseDown={() => null}
                    disabled={true}
                    className="group flex h-full w-full text-left flex-col justify-center rounded-sm bg-blue-500/20 hover:bg-blue-500/40 py-0.5 px-2 text-xs leading-4 disabled:cursor-default disabled:bg-blue-500/20">
                    {!compressedView ? (<>Delivery planned
                        #{dateData?.LoadNumber} - </>) : ''}{compressedView ? dateData?.ShortLocation : dateData?.Location} {!compressedView && `at ${stripTimeFromDate(dateData?.Time)}`}
                </button>
            default:
                return null
        }
    }

    function getDefaultTableOptions() {
        return {
            style: {
                showVerticalLines: false,
                showHorizontalLines: false,
            },
            columns: Object.values(fields).reduce((memo, it) => {
                memo[it.name] = ({
                    name: it.name,
                    isSticky: !!it?.metadata?.isSticky,
                    hidden: false,
                    defaultWidth: it.metadata.width,
                    width: it.metadata.width
                })

                return memo;
            }, {}),
            grossMileageType: "dispatch",
            grossDateType: "pickup",
            compressedView: 0
        }
    }

    function handleLocationClick(it) {
        setIsMapModalOpen(true);
        setSelectedLocation(it);
    }

    useEffect(() => {
        setData(sortData(Array.from(list), sort, dates));

    }, [list, sort, sortBy, queryFields, isLoading]);

    const columns = Object.values(Object.assign({}, fields, getSchedulerFields(dates)));

    return <>
        <CommandCenterTableStyles
            constants={{ccHeaderHeight: "2.2rem"}}
            driverFields={fields}
            schedulerFields={getSchedulerFields(dates)}
            setTableStyles={setTableStyles}
            hasGroups={false}
            queryFields={queryFields}
            tableOptions={tableOptions}
        />

        <TableCard>
            <header
                className="md:px-4 py-2 md:ring-1 ring-black ring-opacity-5 ring-b-none rounded-t-card md:border-b border-tm-gray-300">
                <div className="flex justify-between">
                    <div className="flex gap-2">
                        <FieldsToHtml
                            fieldsState={queryFields}
                            onInputChange={handleQueryChange}
                            translate={translate}
                        />
                    </div>

                    <div className="pt-2 flex items-center gap-2 shrink-0">
                        <div className="flex relative">
                            {/*<button*/}
                            {/*    className={*/}
                            {/*        classNames(*/}
                            {/*            "btn btn-header z-10 rounded-r-none relative -right-px"*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*    onClick={onFetchData}*/}
                            {/*>*/}
                            {/*    <ArrowPathIcon className={*/}
                            {/*        classNames(*/}
                            {/*            "w-5 h-5",*/}
                            {/*            isLoading || isSilentLoading ? "animate-spin" : undefined,*/}
                            {/*            isAutoRefreshEnabled ? "text-primary" : "text-tm-gray-700"*/}
                            {/*        )*/}
                            {/*    }/>*/}
                            {/*</button>*/}

                            {/*<PopOver*/}
                            {/*    btnClass="relative inline-flex items-center rounded-r-btn bg-inverse px-2 py-2 text-gray-400 ring-1 ring-inset ring-tm-gray-300 hover:bg-tm-gray-50 hover:z-10 hover:ring-tm-gray-600"*/}
                            {/*    BtnIcon={ChevronDownIcon}*/}
                            {/*    btnIconClass="w-5 h-5 text-tm-gray-400"*/}
                            {/*    widthClass="max-w-xs"*/}
                            {/*    positionClass="absolute translate-x-0 right-0"*/}
                            {/*>*/}
                            {/*    <div className="bg-popup border border-tm-gray-300 rounded-card p-4">*/}
                            {/*        <label*/}
                            {/*            className="h-10 flex flex-center justify-start px-2 -ml-2 rounded-lg hover:bg-tm-gray-50 cursor-pointer">*/}
                            {/*            <FieldCheckbox*/}
                            {/*                className="checkbox"*/}
                            {/*                onChange={toggleAutoRefresh}*/}
                            {/*                value={isAutoRefreshEnabled}*/}
                            {/*            />*/}

                            {/*            <span className="ml-2 text-sm select-none">*/}
                            {/*                {translate('field.isAutoRefreshEnabled')}*/}
                            {/*            </span>*/}
                            {/*        </label>*/}
                            {/*    </div>*/}
                            {/*</PopOver>*/}
                        </div>

                    </div>
                </div>
            </header>

            {isLoading && (
                <div className="px-6 py-12 flex items-center justify-center">
                    <Loader disableContainer={true}/>
                </div>
            )}

            {!(isLoading || !hasData) && (
                <div className="w-full border-b border-tm-gray-200 max-h-full overflow-auto transform-gpu">
                    <div className="w-full border-x border-tm-gray-200" style={tableStyles}>
                        <div
                            className="flex relative bg-inverse rounded-tl-card rounded-tr">
                            <div
                                className="flex flex-col relative shrink-0 border-r-4 border-tm-gray-200 divide-y-4 divide-tm-gray-300">

                                <header className="sticky top-0 z-20">
                                    <div className="flex">
                                        {
                                            columns.filter(col => !tableOptions.columns?.[col.name]?.hidden).map(col => {
                                                    const canSort = !!col?.metadata?.canSort;
                                                    const DynamicElement = canSort ? "button" : "div"

                                                    return <div
                                                        key={col.name}

                                                        className={classNames(
                                                            "group cc-header col-" + col.name
                                                        )}
                                                    >
                                                        <div className="flex flex-col w-full">
                                                            <DynamicElement
                                                                className={canSort ? "-mx-0.5 px-0.5 py-1 mb-0.5 hover:bg-tm-gray-200 flex items-center rounded-btn" : undefined}
                                                                onClick={canSort ? (() => handleSort(col.name)) : undefined}>
                                                                {col?.metadata?.label ?? translate("field." + col.name)}

                                                                {canSort && (
                                                                    <div className={
                                                                        classNames(
                                                                            col.name === sortBy ? "text-tm-gray-800" : "text-tm-gray-300",
                                                                            "inline-flex flex-shrink-0 ml-2 w-5 h-5"
                                                                        )
                                                                    }>
                                                                        <>
                                                                            {!(col.name === sortBy && sort === "DESC") && (
                                                                                <BarsArrowUpIcon/>
                                                                            )}

                                                                            {col.name === sortBy && sort === "DESC" && (
                                                                                <BarsArrowDownIcon/>
                                                                            )}
                                                                        </>
                                                                    </div>
                                                                )}
                                                            </DynamicElement>
                                                        </div>
                                                    </div>
                                                }
                                            )
                                        }
                                    </div>
                                </header>

                                {data?.[0] && hasData && (
                                    data.map((it, i) => {
                                        return <div key={it.key} className="flex">
                                            <InnerTable
                                                hideHeader={i}
                                                prevRowCount={data?.[i - 1]}
                                                onSort={handleSort}
                                                tableIndex={i}
                                                tableOptions={tableOptions}
                                                fields={Object.assign({}, getFields(), getSchedulerFields(dates))}
                                                comments={comments}
                                                data={data}
                                                sort={sort}
                                                sortBy={sortBy}
                                                translate={translate}
                                            />
                                        </div>
                                    })

                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <NoRecords
                show={!hasData && !isLoading}
                addClass="p-10"
                title="No Data Available"
                text={queryFields.query.value ? "No results available for '" + queryFields.query.value + "'" : "Link that you have provided is invalid or expired. Please contact your link provider."}
            />
        </TableCard>

        <ModalDefault
            show={isMapModalOpen}
            widthClass={"max-w-7xl"}
            title={translate("text.last_known_truck_location")}

            closeButtonLabel={translate("btn.close")}
            onClose={() => setIsMapModalOpen(false)}
        >
            <FleetShareTableMap
                selectedLocation={selectedLocation}
            />
        </ModalDefault>
    </>
}
