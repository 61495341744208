import React, {useEffect, useState} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import LocalStorage from '../../../util/localStorage'
import {useDispatch, useSelector} from 'react-redux'
import {DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY, UPDATE_PERM} from '../../../util/util-constants'
import Resources from '../../../data/services/resources'
import {getSecondResource} from '../../../data/actions/secondResource'
import PayDateFilter from '../../accounting/carrier-pay/carrier-pay-date-filter'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getProp,
    openInNewTab,
    saveTableColumns
} from '../../../common/util/util-helpers'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import Tippy from '@tippyjs/react'
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import Tooltip from "../../../common/components/tooltip";
import ResourceTable from "../../../common/components/resource-table";
import {excludeFields} from "../../../common/util/util-fields";
import moment from "moment";
import {DEFAULT_QUERY_LIMIT} from "../../../common/util/util-consts";
import Pagination from "../../../common/components/resource-table/table-components/pagination";

export default function LateLoadsReportTab({
                                               translate,
                                               pagePath
                                           }) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const secondResource = useSelector((state) => state.secondResource)
    const isLoading = secondResource.isLoading
    const data = secondResource?.data?.list ?? []
    const count = getProp(secondResource, 'data.count', 0)

    /** Const
     ================================================================= */
    const LatePercentage = secondResource?.data?.LatePercentage ?? 0
    const LateOnPickup = secondResource?.data?.LateOnPickup ?? 0
    const LateOnDelivery = secondResource?.data?.LateOnDelivery ?? 0
    const TotalLateLoads = secondResource?.data?.TotalLateLoads ?? 0
    /** Helpers
     ================================================================= */
    const renderDriverNameField = (item, name) => {
        if (!item) return null;
        return (
            <button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    checkPerm(Resources.DriverInfo, UPDATE_PERM) && openInNewTab(`/drivers/info/${item.DriverID}`);
                }}
                className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
            >
                {item[name]}
            </button>
        )
    }


    const getFields = () => {
        return {
            DriverID: new Field('DriverID', '', [''], false, 'text', {
                addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                render: (item) => renderDriverNameField(item, 'Driver')
            }),
            // DriverContactID: new Field('DriverContactID', '', [''], false, 'select-search'),
            LateOnPickup: new Field('LateOnPickup', '', [''], false, 'float'),
            LateOnDelivery: new Field('LateOnDelivery', '', [''], false, 'float'),
            TotalLateLoads: new Field('TotalLateLoads', '', [''], false, 'float'),
            LatePercentage: new Field('LatePercentage', '', [''], false, 'float'),
            LoadRefs: new Field('LoadRefs', '', [''], false, 'float', {
                render: (it) =>
                    <Tooltip
                        content={<div className={"text-break"}>{it.LoadRefs}</div>}
                    >
                        <div dir="rtl" className={"max-w-full truncate"}>{it.LoadRefs}</div>
                    </Tooltip>

            })
        }
    }

    const getQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            limit: new Field('limit', DEFAULT_QUERY_LIMIT, [''], false, 'select', {
                hideLabel: true,
                labelType: "float"
            }, {menuPlacement: "top"}),
            StartDate: new Field('StartDate', moment().subtract('month', 1).format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
            EndDate: new Field('EndDate', moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                data: {1: 'Pickup', 2: 'Delivery'},
                addClass: 'w-full',
                addButtonClass: 'uppercase font-semibold',
                addContainerClass: 'col-span-2',
                labelType: 'stack',
                activeFilterLabel: "filter_pickup_date"
            }, {}),
            DriverID: new Field('DriverID', '', [], false, 'select-search'),
            CustomerID: new Field('CustomerID', '', [], false, 'select-search')
        }
    }

    const getResourceName = () => {
        return Resources.LateLoadsReport
    }

    const getQuery = () => {
        return {
            offset: query.offset,
            sort: query.sort,
            sortBy: query.sortBy,

            ...FieldsManager.getFieldKeyValues(queryFilterFields)
        }
    }

    const getTableOptions = (pagePath, translate) => {
        const tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: false
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        return getDefaultTableOptions(getFields(), tablePageDefaults, pagePath, translate)
    }

    /** State
     ================================================================= */
    const [query, setQuery] = useState(Object.assign({}, DEFAULT_CRUD_STATE, {
        sort: 'DESC',
        sortBy: 'DriverID',
    }))
    const [queryFilterFields, setQueryFilterFields] = useState(getQueryFields())
    const [tableOptions, setTableOptions] = useState(getTableOptions(pagePath, translate))

    const [isTableFullScreen, setIsTableFullScreen] = useState(false)

    const [sort, setSort] = useState("ASC")
    const [sortBy, setSortBy] = useState("DriverID")

    const metadata = {
        DriverIDs: {
            api: 'api/' + Resources.DriversQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.DriverID,
                label: item.FirstName + " " + item.LastName
            })
        },
        CustomerID: {
            api: 'api/' + Resources.CustomersQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                label: item.LegalName,
                value: item.CustomerID,
            })
        },
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: getQuery()
        }))
    }


    const downloadExcel = () => {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: Object.assign({format: 'EXCEL', name: 'late_loads_report' + currentDate() + '.xlsx'}, getQuery())
        }))
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [sort, sortBy, queryFilterFields])

    /** UI events
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        let queryFieldsUpdate = FieldsManager.updateField(queryFilterFields, name, value);

        if (name === 'StartDate') {
            queryFieldsUpdate.EndDate.props.minDate = value
        }
        if (name === 'EndDate') {
            queryFieldsUpdate.StartDate.props.maxDate = value
        }

        if (name === 'DateType') {
            queryFieldsUpdate.DateType.metadata.activeFilterLabel = value === "1" ? "filter_pickup_date" : "filter_delivery_date"
        }

        setQueryFilterFields(queryFieldsUpdate)
        handleResetPagination();
    }

    const handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFieldsTmp = Object.assign({}, queryFilterFields)
        const defaultExcludedFields = ['DateType','limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFieldsTmp).filter((it) => !excludedFields.includes(it.name)).forEach((it) => {
            FieldsManager.updateField(queryFilterFieldsTmp, it.name, '')
        })

        setQueryFilterFields(queryFilterFieldsTmp)
        handleResetPagination()
    }

    const handleFullScreenToggle = () => {
        setIsTableFullScreen(!isTableFullScreen)
    }

    const setOptions = (options) => {
        setTableOptions(options)
        saveTableColumns(pagePath, options)
    }

    const handleUpdateSort = (sortBy) => {
        setQuery((prevState) => ({
            ...prevState,
            sortBy: sortBy,
            sort: (query.sortBy === sortBy) ? (query.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        }))

        let newSort = (sortBy === sortBy) ? (sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        setSortBy(sortBy)
        setSort(newSort)
    }

    const handleUpdateOffset = (offset, num) => {
        setQuery((prevState) => ({
            ...prevState,
            offset: offset,
            paginationPage: num
        }))
    }

    const handleResetPagination = () => {
        setQuery((prevState) => ({
            ...prevState,
            offset: 0,
            paginationPage: 1
        }))
    }

    /** Render
     ================================================================= */
    return (
        <React.Fragment>
            <div
                className={"flex items-center mb-4"}>
                <span
                    className={"font-bold"}>{!isLoading ? ("Late percentage: " + LatePercentage * 100 + "%") : ""}</span>

                <span className={"font-bold mx-4"}>{!isLoading ? ("Late on Pickup: " + LateOnPickup) : ""}</span>

                <span className={"font-bold mx-4"}>{!isLoading ? ("Late on delivery: " + LateOnDelivery) : ""}</span>

                <span className={"font-bold mx-4"}>{!isLoading ? ("Total late loads: " + TotalLateLoads) : ""}</span>

                <div className="flex max-w-fit mx-4 h-8 gap-4">
                </div>
            </div>

            <div className={"sm:flex justify-between"}>
                <ActiveFilters
                    addClass={"mb-2"}
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    persistFilters={['DateType']}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />

                <Tippy content={translate('text.download_excel')}>
                    <button
                        className="btn-icon"
                        onClick={downloadExcel}
                    >
                        <DocumentArrowDownIcon className="w-6 h-6"/>
                    </button>
                </Tippy>
            </div>
            <TableCard
                className={
                    classNames(
                        "",
                        isTableFullScreen
                            ? "bg-inverse rounded-card fixed inset-1 -top-1 z-50"
                            : "flex flex-col h-dialog-body md:bg-inverse md:border border-tm-gray-300 md:shadow-card"
                    )}
            >
                <div>
                    <TableFilters
                        forceDialog
                        hideLimit
                        filterFields={excludeFields(queryFilterFields, ['StartDate', 'EndDate', 'DateType'])}
                        handleInputChange={handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={fetchData}
                        selects={metadata}
                        onFullScreenToggle={handleFullScreenToggle}
                        isTableFullScreen={isTableFullScreen}
                        isLoading={isLoading}
                        customHtml={
                            <PayDateFilter
                                queryFields={queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    setQueryFilterFields(queryFieldsUpdate)
                                }}
                                onQueryChange={handleFilterInputChange}
                            />
                        }
                    />
                </div>

                <ResourceTable
                    tableKey={'DriverID'}
                    data={data}
                    fields={getFields()}
                    options={tableOptions}
                    isLoading={isLoading}
                    translate={translate}
                    sort={sort}
                    sortBy={sortBy}
                    onSortChange={handleUpdateSort}
                    saveTableOptions={setOptions}
                    maxHeightClass={"max-h-[calc(100vh-26rem)]"}
                    limit={query.limit}
                    offset={query.offset}
                    page={query.paginationPage}
                    paginationButtonLimit={5}
                    onOffsetChange={handleUpdateOffset}
                />

                {!(!data.length && !isLoading) && (
                    <div
                        className="bg-inverse px-4 py-3 flex items-center justify-between ring-1 ring-black ring-opacity-5 sm:px-6 h-16 rounded-b-lg"
                    >
                        <Pagination
                            count={count}
                            isLoading={isLoading}
                            handleQueryChange={
                                (name, value, currentPage) => name === "offset"
                                    ? handleUpdateOffset(value, currentPage)
                                    : handleFilterInputChange(name, value)
                            }
                            pageOffset={query.offset}
                            queryFields={queryFilterFields}
                            translate={translate}
                        />
                    </div>
                )}

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    title={'No matching records found'}
                    className={"pb-12 pt-16 px-6"}
                />
            </TableCard>
        </React.Fragment>
    )
}
