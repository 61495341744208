import React, {Component} from 'react'
import LocalStorage from '../../../../util/localStorage'
import {getProp} from '../../../../common/util/util-helpers'
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";
import {sendUserMessage} from "../../../../package/realtime/actions/realtime";
import ChatMessages from "./chat-messages";
import {getJWT} from "../../../../common/util/util-auth";
import slateValueToHTML from '../../../../common/components/fields/rich-text-editor/slate-serialize'
import FieldTextareaChat from "../../../../common/components/fields/field-textarea-chat";

export default class LoadInternalChatTab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            message: "",
            notifyMe: false,
            isDirty: false,
            chatBoxHeight: "48px",
            messageUpdateKey: 0
        }

        this.myID = LocalStorage.get('user')?.ContactID;
        this.scrollRef = React.createRef();
    }

    /** Life cycle
     ================================================================= */
    componentDidMount() {
        this.scrollToEnd();
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.loadPresence?.messages?.UserMessages && this.props.loadPresence.messages.UserMessages.length !== prevProps.loadPresence?.messages?.UserMessages?.length) {
            this.scrollToEnd();
        }
    }

    /** Data Events
     ================================================================= */
    handleSendMessageClick = () => {
        const htmlMessage = slateValueToHTML(this.state.message);

        // Ignore empty messages
        if (!htmlMessage.replace(/[\s\n]|<\/?p>/g, '')) {
            return false;
        }

        const mention = this.state.message.reduce((memo, paragraph) => {
            paragraph.children.forEach(child => {
                if (child.type === 'mention') {
                    memo.push({
                        ContactID: child.id,
                        Email: child.email
                    })
                }
            });

            return memo;
        }, []);

        this.props.dispatch(sendUserMessage({
            LoadID: this.props.loadID,
            Message: htmlMessage,
            Mention: mention
        }));

        this.setState({
            messageUpdateKey: this.state.messageUpdateKey + 1
        });
    }

    setChatInputValue = (message) => {
        this.setState({
            message: message,
            isDirty: !!message?.[0]?.children?.[0]?.text || message?.[0]?.children.length > 1
        });
    }

    onToggleNotify = () => {
        this.setState({notifyMe: !this.state.notifyMe})
    }

    /** Helpers
     ================================================================= */
    scrollToEnd = () => {
        const scrollEl = this.scrollRef.current
        if (scrollEl) {
            scrollEl.scrollTop = scrollEl.scrollHeight - scrollEl.clientHeight;
        }
    }

    /** Render
     ================================================================= */
    render() {
        const messages = getProp(this.props.loadPresence, 'messages.UserMessages', []);

        return (
            <React.Fragment>
                <div className="flex-1 justify-between flex flex-col max-w-lg">
                    <div
                        className="flex sm:items-center justify-between py-3 border-b-2 border-tm-gray-200">
                        <div className="flex items-center space-x-4 px-6">
                            <div className="flex flex-col leading-tight">
                                <span
                                    className="text-tm-gray-600">{this.props.translate('text.loadChatVisibleOnly')}</span>
                            </div>
                        </div>
                        <div className="flex items-center space-x-2">
                            {/*<button type="button"*/}
                            {/*        className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-light focus:outline-none">*/}
                            {/*    <MagnifyingGlassIcon className="w-6 h-6"/>*/}
                            {/*</button>*/}

                            {/*<button*/}
                            {/*    type="button"*/}
                            {/*    className={classNames(this.state.notifyMe ? 'bg-primary text-white' : 'hover:bg-light text-tm-gray-500', 'inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out focus:outline-none')}*/}
                            {/*    onClick={() => this.onToggleNotify()}*/}
                            {/*>*/}
                            {/*    <BellIcon className="w-6 h-6"/>*/}
                            {/*</button>*/}
                        </div>
                    </div>

                    <div className="h-[calc(100vh-356px)] flex flex-col">
                        <div
                            ref={this.scrollRef}
                            id="messages"
                            className="flex flex-col space-y-4 p-3 overflow-y-auto grow"
                        >
                            <ChatMessages
                                myID={this.myID}
                                messages={messages}
                            />
                        </div>

                        <div className="border-t-2 flex gap-2 border-tm-gray-200 px-4 pt-4 mb-2 sm:mb-0">
                            <FieldTextareaChat
                                addInputClass="!min-h-[46px] text-base px-1"
                                placeholder="Write something"
                                key={this.state.messageUpdateKey}
                                token={getJWT().access_token}
                                onSetValue={this.setChatInputValue}
                                onEnterKeyDown={this.handleSendMessageClick}
                            />

                            <div className="text-right">
                                <button
                                    disabled={!this.state.isDirty}
                                    className="disabled:bg-tm-gray-200 text-primary-contrast bg-primary hover:bg-primary-tint focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary inline-flex items-center justify-center rounded-card h-12 w-12 transition duration-500 ease-in-out text-white"
                                    onClick={this.handleSendMessageClick}
                                >
                                    <PaperAirplaneIcon className="h-6 w-6"/>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }
}

