import React, {useEffect, useState} from 'react'
import {Field, FieldsManager} from '../../../data/services/fields'
import LocalStorage from '../../../util/localStorage'
import {useDispatch, useSelector} from 'react-redux'
import {DEFAULT_CRUD_STATE, DEFAULT_METADATA_SELECT_SEARCH_QUERY, UPDATE_PERM} from '../../../util/util-constants'
import Resources from '../../../data/services/resources'
import {getSecondResource} from '../../../data/actions/secondResource'
import PayDateFilter from '../../accounting/carrier-pay/carrier-pay-date-filter'
import {
    getDefaultTableOptions, getProp,
    mileageToErrorMessage,
    openInNewTab,
    returnMileage,
    saveTableColumns
} from '../../../common/util/util-helpers'
import EyeIcon from '@heroicons/react/24/outline/EyeIcon'
import {checkPerm, classNames, getLookup} from '../../../common/util/util-helpers'
import {genericMoneyFormatter} from '../../../common/util/util-vanilla'
import {download} from '../../../data/actions/download'
import {currentDate} from '../../../common/util/util-dates'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import Tippy from '@tippyjs/react'
import {getThirdResource} from "../../../data/actions/thirdResource";
import {showModal} from "../../../data/actions/ui";
import {ArrowPathIcon, ArrowsPointingOutIcon, IdentificationIcon} from "@heroicons/react/24/outline";
import {ArrowsPointingInIcon} from "@heroicons/react/20/solid";
import {numberWithCommas} from '../../../util/util-formaters'
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import ReactPortal from "../../../common/components/layout/layout-components/react-portal";
import ModalDefault from "../../../common/components/modal/modal-default";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import Badge from "../../../common/components/badge";
import InfoParagraph from "../../../common/components/info-paragraph";
import CellLink from "../../../common/components/resource-table/table-components/cell-link";
import Tooltip from "../../../common/components/tooltip";
import CellButton from "../../../common/components/resource-table/table-components/cell-button";
import ResourceTable from "../../../common/components/resource-table";
import {excludeFields} from "../../../common/util/util-fields";
import moment from "moment";
import Page from "../../../common/components/layout/layout-components/page";

export default function TrucksGrossReportTab({
                                           translate,
                                           pagePath
                                       }) {

    /** Store
     ================================================================= */
    const dispatch = useDispatch()
    const secondResource = useSelector((state) => state.secondResource)
    const isLoading = secondResource.isLoading
    const data = secondResource?.data?.list ?? []
    const count = getProp(secondResource.data, 'count', 0)

    /** Const
     ================================================================= */

    /** Helpers
     ================================================================= */
    const renderTruckNameField = (item) => {
        if (!item) return null;
        return (
            <button
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    checkPerm(Resources.TrucksInfo, UPDATE_PERM) && openInNewTab(`/trucks/info/${item.TruckID}`);
                }}
                className="btn btn-text hover:bg-sky-600/10 hover:text-primary focus-visible:bg-sky-600/10 focus:outline-none focus:ring-0"
            >
                {item['Truck']}
            </button>
        )
    }

    const getFields = () => {
        return {
            TruckID: new Field('TruckID', '', [''], false, 'text', {
                addContainerClass: 'col-span-full sm:col-span-6 lg:col-span-3',
                render: (item) => renderTruckNameField(item)
            }),
            AmountGross: new Field('AmountGross', '', [''], false, 'money'),
            AmountExpenses: new Field('AmountExpenses', '', [''], false, 'money'),
            AmountNet: new Field('AmountNet', '', [''], false, 'money'),
            TotalMiles: new Field('TotalMiles', '', [''], false, 'float'),
            EmptyMiles: new Field('EmptyMiles', '', [''], false, 'float'),
            // CalculatedMiles: new Field('CalculatedMiles', '', [''], false, 'float', {
            //     label: "CalculatedMiles",
            //     render: (item) => {
            //         if (!item.isFooterData) {
            //             return numberWithCommas(parseInt(item.TotalMiles) + parseInt(item.EmptyMiles))
            //         }
            //     }
            // }),
            RPM: new Field('RPM', '', [], false, 'float'),
            LoadRefs: new Field('LoadRefs', '', [''], false, 'float', {
                render: (it) =>
                    <Tooltip
                        content={<div className={"text-break"}>{it.LoadRefs}</div>}
                    >
                        <div dir="rtl" className={"max-w-full truncate"}>{it.LoadRefs}</div>
                    </Tooltip>

            })
        }
    }

    const getQueryFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search'),
            StartDate: new Field('StartDate', moment().subtract('month', 1).format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
            EndDate: new Field('EndDate', moment().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'), [], false, 'date', {}, {}),
            DateType: new Field('DateType', 1, [''], false, 'button-group', {
                data: {1: 'Pickup', 2: 'Delivery'},
                addClass: 'w-full',
                addButtonClass: 'uppercase font-semibold',
                addContainerClass: 'col-span-2',
                labelType: 'stack',
                activeFilterLabel: "filter_pickup_date"
            }, {}),
            TruckIDs: new Field('TruckIDs', '', [''], false, 'select-search', {}, {
                multi: true,
                all: true
            }),
        }
    }

    const getResourceName = () => {
        return Resources.TrucksGrossReport
    }

    const getQuery = () => {
        return {
            ...FieldsManager.getFieldKeyValues(queryFilterFields),
            sort: sort,
            sortBy: sortBy
        }
    }

    const getTableOptions = (pagePath, translate) => {
        const tablePageDefaults = {
            behaviour: {
                rowSelect: true,
                canAdjustWidth: true,
                hasMenu: false
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        return getDefaultTableOptions(getFields(), tablePageDefaults, pagePath, translate)
    }


    /** State
     ================================================================= */
    const [queryFilterFields, setQueryFilterFields] = useState(getQueryFields())
    const [tableOptions, setTableOptions] = useState(getTableOptions(pagePath, translate))
    const [isTableFullScreen, setIsTableFullScreen] = useState(false)
    const [sort, setSort] = useState("ASC")
    const [sortBy, setSortBy] = useState("TruckID")

    const metadata = {
        TruckIDs: {
            api: 'api/' + Resources.TrucksQuick,
            query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
            searchMap: (item) => ({
                value: item.TruckID,
                label: item.Truck
            })
        }
    }

    /** Data events
     ================================================================= */
    const fetchData = () => {
        dispatch(getSecondResource({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: getQuery()
        }))
    }


    const downloadExcel = () => {
        dispatch(download({
            user: LocalStorage.get('user'),
            resource: getResourceName(),
            query: Object.assign({format: 'EXCEL', name: 'truck_gross_report' + currentDate() + '.xlsx'}, getQuery())
        }))
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        fetchData()
    }, [sort, sortBy, queryFilterFields])

    /** UI events
     ================================================================= */
    const handleFilterInputChange = (name, value) => {
        let queryFieldsUpdate = FieldsManager.updateField(queryFilterFields, name, value);

        if (name === 'StartDate') {
            queryFieldsUpdate.EndDate.props.minDate = value
        }
        if (name === 'EndDate') {
            queryFieldsUpdate.StartDate.props.maxDate = value
        }

        if (name === 'DateType') {
            queryFieldsUpdate.DateType.metadata.activeFilterLabel = value === "1" ? "filter_pickup_date" : "filter_delivery_date"
        }

        setQueryFilterFields(queryFieldsUpdate)
    }

    const handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFieldsTmp = Object.assign({}, queryFilterFields)
        const defaultExcludedFields = ['DateType','limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFieldsTmp).filter((it) => !excludedFields.includes(it.name)).forEach((it) => {
            FieldsManager.updateField(queryFilterFieldsTmp, it.name, '')
        })

        setQueryFilterFields(queryFilterFieldsTmp)
    }

    const handleFullScreenToggle = () => {
        setIsTableFullScreen(!isTableFullScreen)
    }

    const setOptions = (options) => {
        setTableOptions(options)
        saveTableColumns(pagePath, options)
    }

    const handleUpdateSort = (sortByTmp) => {
        setSort((sortByTmp === sortBy) ? (sort === "ASC" ? "DESC" : "ASC") : "ASC")
        setSortBy(sortByTmp)
    };

    /** Render
     ================================================================= */
    return (
        <React.Fragment>
            <div
                className={"flex items-center mb-4"}>
                <div className="flex max-w-fit mx-4 h-8 gap-4">
                    <InfoParagraph type="info">
                        <div className={"text-sm"}>
                            {translate("text.totalAreCalculated")}
                        </div>
                    </InfoParagraph>

                    <Badge type="outline">
                        {!isLoading ?
                            (
                                <div className="flex items-center gap-1.5">
                                    <IdentificationIcon className="w-5 h-5"/>
                                    <span>{translate("text.truck_count")}:</span>{count}<span
                                    className="font-bold"></span>
                                </div>
                            ) : ""}
                    </Badge>
                </div>
            </div>

            <div className={"sm:flex justify-between"}>
                <ActiveFilters
                    addClass={"mb-2"}
                    filterFields={queryFilterFields}
                    onLabelClick={handleFilterInputChange}
                    persistFilters={['DateType']}
                    onClearFiltersClick={handleClearFiltersClick}
                    translate={translate}
                />

                <Tippy content={translate('text.download_excel')}>
                    <button
                        className="btn-icon"
                        onClick={downloadExcel}
                    >
                        <DocumentArrowDownIcon className="w-6 h-6"/>
                    </button>
                </Tippy>
            </div>
            <TableCard
                className={
                    classNames(
                        "",
                        isTableFullScreen
                            ? "bg-inverse rounded-card fixed inset-1 -top-1 z-50"
                            : "flex flex-col h-dialog-body md:bg-inverse md:border border-tm-gray-300 md:shadow-card"
                    )}
            >
                <div>
                    <TableFilters
                        forceDialog
                        hideLimit
                        filterFields={excludeFields(queryFilterFields, ['StartDate', 'EndDate', 'DateType'])}
                        handleInputChange={handleFilterInputChange}
                        translate={translate}
                        onRefreshTable={fetchData}
                        selects={metadata}
                        onFullScreenToggle={handleFullScreenToggle}
                        isTableFullScreen={isTableFullScreen}
                        isLoading={isLoading}
                        customHtml={
                            <PayDateFilter
                                queryFields={queryFilterFields}
                                translate={translate}
                                updateQueryFields={(queryFieldsUpdate) => {
                                    setQueryFilterFields(queryFieldsUpdate)
                                }}
                                onQueryChange={handleFilterInputChange}
                            />
                        }
                    />
                </div>

                <ResourceTable
                    tableKey={'TruckID'}
                    data={data}
                    fields={getFields()}
                    options={tableOptions}
                    isLoading={isLoading}
                    translate={translate}
                    sort={sort}
                    sortBy={sortBy}
                    onSortChange={handleUpdateSort}
                    saveTableOptions={setOptions}
                    maxHeightClass={"max-h-[calc(100vh-23rem)]"}
                />

                <NoRecordsTable
                    show={(data.length === 0) && !isLoading}
                    title={'No matching records found'}
                    className={"pb-12 pt-16 px-6"}
                />
            </TableCard>
        </React.Fragment>
    )
}
