import {toFrontDate} from "../../../util/util-dates";
import {
    createResourceDiffSilent,
    deleteResourceDiff, deleteResourceDiffSilent,
    updateResourceDiffSilent
} from "../../../../data/actions/resourceDiff";
import LocalStorage from "../../../util/localStorage";
import Resources from "../../../../data/services/resources";
import Button from "../../button";
import {classNames} from "../../../util/util-helpers";
import TrashIcon from "@heroicons/react/24/outline/TrashIcon";
import React, {useEffect, useState} from "react";
import {Field, FieldsManager} from "../../../../data/services/fields";
import {useDispatch} from "react-redux";
import {fillFieldsFromData} from "../../../util/util-fields";
import ModalDefault from "../../modal/modal-default";
import Fields from "../../fields/fields-to-html";
import {COLOR_PICKER_COLOR} from "../../../../util/util-constants";
import CheckIcon from "@heroicons/react/20/solid/CheckIcon";
import moment from "moment";
import ModalConfirm from "../../modal/modal-confirm";

export default function CommandCenterCommentModal({
                                                      isClosestMatchListingActive,
                                                      selectedComment,
                                                      selectedDriver,
                                                      selectedItem,
                                                      setSelectedDriver,
                                                      isCommentModalOpen,
                                                      setIsCommentModalOpen,
                                                      closesMatchData,
                                                      setSilentLoadingCell,
                                                      translate
                                                  }) {
    const dispatch = useDispatch();
    const [fields, setFields] = useState(() => getFields())
    const isCommentSelected = !!Object.keys(selectedComment)?.length;
    // const [selectedComment, setSelectedComment] = useState({});
    const [autocompleteFields, setAutocompleteFields] = useState({});
    const [isConfirmDeleteCommentModalOpen, setIsConfirmDeleteCommentModalOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState(COLOR_PICKER_COLOR[0]);

    function handleDeleteCommentClick() {
        setIsConfirmDeleteCommentModalOpen(true);
        // setSelectedComment(it);
    }

    function getFields(data = {}) {
        const fieldTemplates = {
            GooglePlaces: new Field('GooglePlaces', '', [], false, 'google-locations', {
                setLocations: handleSetAutocompleteFields,
                addContainerClass: 'col-span-full'
            }),
            AddressName: new Field('AddressName', '', [], true, "text", {addContainerClass: "col-span-full"}),
            CityName: new Field('CityName', '', [''], true, "text", {addContainerClass: "col-span-5"}),
            StateID: new Field('StateID', '', [''], true, 'select', {addContainerClass: "col-span-5"}),
            PostalCode: new Field('PostalCode', '', [''], true, "text", {addContainerClass: "col-span-2"}),
            Latitude: new Field('Latitude', '', [], false, "hidden"),
            Longitude: new Field('Longitude', '', [], false, "hidden"),
            Comment: new Field('Comment', '', [''], false, "textarea", {
                addContainerClass: "col-span-full",
            }),
        }

        return fillFieldsFromData(fieldTemplates, data)
    }

    function handleConfirmDeleteCommentClick() {
        setIsConfirmDeleteCommentModalOpen(false);

        dispatch(deleteResourceDiffSilent({
            user: LocalStorage.get("user"),
            query: Object.assign({}, {
                    ScheduleCommentID: selectedComment.ScheduleCommentID,
                }, isClosestMatchListingActive
                    ? {
                        SearchLatitude: closesMatchData.Latitude,
                        SearchLongitude: closesMatchData.Longitude,
                        TargetDate: closesMatchData.TargetDate
                    }
                    : {}
            ),
            errorMessage: true, successMessage: translate("text.comment_deleted"),
            resource: Resources.UnitsScheduleComment,
            piggyResource: Resources.UnitsSchedule
        }));

        setSilentLoadingCell({
            DriverID: selectedDriver?.DriverID,
            Date: selectedItem
        });
    }

    function handleInputChange(name, value) {
        let fieldsUpdate = Object.assign({}, fields);
        fieldsUpdate = FieldsManager.updateField(fieldsUpdate, name, value);
        setFields(fieldsUpdate);
    }

    function handleSetAutocompleteFields(fields) {
        setAutocompleteFields(Object.assign({}, fields));
    }

    useEffect(() => {
        const fieldsUpdate = Object.assign({}, fillFieldsFromData(getFields(Object.assign({Comment: fields.Comment.value}, autocompleteFields))))
        setFields(fieldsUpdate);
    }, [autocompleteFields]);

    useEffect(() => {
        if (isCommentModalOpen) {
            setFields(getFields(selectedComment));
        }
    }, [isCommentModalOpen]);

    return <ModalDefault
        show={isCommentModalOpen}
        widthClass="max-w-3xl"
        title={(isCommentSelected ? "Edit" : "Add") + " comment - " + (selectedDriver?.Driver ? selectedDriver?.Driver + " - " : " day - ") + toFrontDate(selectedItem)}
        onClose={() => {
            setIsCommentModalOpen(false);
            setSelectedDriver({});
        }}
        closeButtonLabel={translate('btn.close')}
        buttonLabel={translate("btn.save")}
        onButtonClick={() => {
            const validatedFields = FieldsManager.validateFields(fields);
            if (FieldsManager.checkFieldsForErrors(validatedFields)) {
                const params = Object.assign(
                    {},
                    FieldsManager.getFieldKeyValues(validatedFields),
                    {ColorTag: selectedColor},
                    {Date: selectedItem + " 00:00:00"},
                    selectedDriver?.DriverID ? {DriverID: selectedDriver?.DriverID} : {}
                );

                if (isCommentSelected) {
                    dispatch(updateResourceDiffSilent({
                        user: LocalStorage.get('user'),
                        params: Object.assign(
                            {},
                            params,
                            {Date: selectedItem + " 00:00:00"},
                            selectedDriver?.DriverID ? {DriverID: selectedDriver?.DriverID} : {},
                            {ScheduleCommentID: selectedComment.ScheduleCommentID}
                        ),
                        resource: Resources.UnitsScheduleComment,
                        piggyResource: Resources.UnitsSchedule,
                        query: Object.assign({}, isClosestMatchListingActive
                            ? {
                                SearchLatitude: closesMatchData.Latitude,
                                SearchLongitude: closesMatchData.Longitude,
                                TargetDate: closesMatchData.TargetDate
                            }
                            : {}
                        ),
                        successMessage: translate("text.comment_updated_for_date", [moment(selectedItem)?.format('dddd') + ", " + toFrontDate(selectedItem)]),
                        errorMessage: true
                    }));
                } else {
                    dispatch(createResourceDiffSilent({
                        user: LocalStorage.get('user'),
                        params: Object.assign(
                            {},
                            params,
                            {Date: selectedItem + " 00:00:00"},
                            selectedDriver?.DriverID ? {DriverID: selectedDriver?.DriverID} : {}
                        ),
                        resource: Resources.UnitsScheduleComment,
                        piggyResource: Resources.UnitsSchedule,
                        query: Object.assign({}, isClosestMatchListingActive
                            ? {
                                SearchLatitude: closesMatchData.Latitude,
                                SearchLongitude: closesMatchData.Longitude,
                                TargetDate: closesMatchData.TargetDate
                            }
                            : {}
                        ),
                        successMessage: translate("text.comment_added_for_date", [moment(selectedItem)?.format('dddd') + ", " + toFrontDate(selectedItem)]),
                        errorMessage: true
                    }));
                }

                setSilentLoadingCell({
                    DriverID: selectedDriver?.DriverID,
                    Date: selectedItem
                });

                setIsCommentModalOpen(false);
                setSelectedDriver({});
            } else {
                setFields(Object.assign({}, validatedFields));
            }
        }}
        customHtmlLeft={isCommentSelected && (
            <Button
                onClick={handleDeleteCommentClick}
                appearance="danger"
            >
                <TrashIcon className="w-5 h-5 mr-1 -ml-1"/>
                Delete comment
            </Button>
        )}
        translate={translate}
    >
        <div>
            <div
                className="p-6 grid grid-cols-12 gap-4"
            >
                <Fields
                    fieldsState={fields}
                    onInputChange={handleInputChange}
                    translate={translate}
                />
            </div>

            <div className="px-6 mb-6 max-w-xl">
                <span className="label-stack">{translate('text.colors')} *</span>
                <div className="grid gap-4 grid-cols-10 pt-4 pb-2">
                    {
                        COLOR_PICKER_COLOR.map((it, i) => (
                            <button
                                key={i}
                                onClick={() => setSelectedColor(it)}
                                className={classNames(
                                    'w-10 h-10 rounded-full ring-offset-inverse ring-link focus:ring-2 focus:ring-offset-2 flex items-center justify-center',
                                )}
                                style={{background: it}}
                            >
                                {it === selectedColor && (
                                    <CheckIcon className="w-7 h-7 text-white"/>
                                )}
                            </button>
                        ))
                    }
                </div>
            </div>

            <ModalConfirm
                title="Are you sure you want to delete this comment?"
                show={isConfirmDeleteCommentModalOpen}
                text={selectedComment?.Comment}
                onClose={() => {
                    setIsConfirmDeleteCommentModalOpen(false);
                }}
                buttonLabel={translate("btn.confirm")}
                closeButtonLabel={translate("btn.cancel")}
                translate={translate}
                onConfirm={() => {
                    handleConfirmDeleteCommentClick();
                    setIsCommentModalOpen(false);
                }}
            />
        </div>
    </ModalDefault>
}