import ChatMessages from "./chat-messages";
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";
import React, {useEffect, useRef, useState} from "react";
import {sendDriverMessage} from "../../../../package/realtime/actions/realtime";
import {useDispatch} from "react-redux";
import LocalStorage from "../../../../util/localStorage";
import FieldTextarea from "../../../../common/components/fields/field-textarea";
import {JSONParseFix} from "../../../../common/util/util-helpers";

export default function LoadDriverChatTab({translate, loadID, loadPresence}) {
    const chatMessages = (loadPresence?.messages?.DriverMessages ?? [])
        .filter(it => it?.Message || it?.JsonData?.S)
        .map(it => {
        if (it?.Message) {
            return it;
        }

        return JSONParseFix(it?.JsonData?.S);
    });

    const scrollRef = useRef();
    const myID = LocalStorage.get('user')?.ContactID;

    const dispatch = useDispatch();

    const [message, setMessage] = useState("");
    const [isDirty, setIsDirty] = useState(false);



    /** Data Events
     ================================================================= */
    function onMessageSend() {
        dispatch(sendDriverMessage({
            LoadID: loadID,
            Message: message
        }));

        setMessage("");
    }

    /** UI Events
     ================================================================= */
    function scrollToEnd() {
        const scrollEl = scrollRef.current;

        if (scrollEl) {
            scrollEl.scrollTop = scrollEl.scrollHeight - scrollEl.clientHeight;
        }
    }

    function handleInputChange(message) {
        setMessage(message);
        setIsDirty(true);
    }

    /** Lifecycle
     ================================================================= */
    useEffect(() => {
        setTimeout(() => scrollToEnd(), 200);
    }, [loadPresence]);

    /** Render
     ================================================================= */
    return (
        <div className="flex-1 justify-between flex flex-col max-w-lg">
            <div
                className="flex sm:items-center justify-between py-3 border-b-2 border-tm-gray-200"
            >
                <div className="flex items-center space-x-4 px-6">
                    <div className="flex flex-col leading-tight">
                        <span
                            className="text-tm-gray-600">
                            {translate('text.driverChatInfo')}
                        </span>
                    </div>
                </div>
            </div>

            <div className="h-[calc(100vh-356px)] flex flex-col">
                <div
                    ref={scrollRef}
                    id="messages"
                    className="flex flex-col space-y-4 p-3 overflow-y-auto grow"
                >
                    <ChatMessages
                        myID={myID}
                        messages={chatMessages}
                    />
                </div>

                <div className="border-t-2 flex gap-2 border-tm-gray-200 px-4 pt-4 mb-2 sm:mb-0">
                    <FieldTextarea
                        autoFocus={true}
                        rows={2}
                        value={message}
                        onChange={(_, value) => handleInputChange(value)}
                        placeholder="Write something"
                        addClass="border w-full focus:ring-2 focus:ring-primary focus:ring-offset-inverse focus:ring-offset-0 focus:border-transparent focus:placeholder-tm-gray-400 text-tm-gray-600 placeholder-tm-gray-400 bg-inverse px-1 border border-tm-gray-200 rounded-card py-0 resize-none whitespace-pre-wrap"
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                isDirty ? onMessageSend() : null
                            }
                        }}
                    />

                    <div className="text-right">
                        <button
                            disabled={!isDirty}
                            className="disabled:bg-tm-gray-200 text-primary-contrast bg-primary hover:bg-primary-tint focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-inverse focus:ring-primary inline-flex items-center justify-center rounded-card h-12 w-12 transition duration-500 ease-in-out text-white"
                            onClick={onMessageSend}
                        >
                            <PaperAirplaneIcon className="h-6 w-6"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}