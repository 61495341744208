import { classNames } from '../../../../common/util/util-helpers'
import React from 'react'
import {EyeIcon, PencilIcon, TrashIcon} from '@heroicons/react/24/outline'
import { PlusIcon, ArrowTrendingDownIcon } from '@heroicons/react/20/solid'
import Card from "../../../../common/components/card";
import ResourceTable from "../../../../common/components/resource-table";
import NoRecords from "../../../../common/components/no-records-found/no-records";

export default function LoadPricingDriversFee({
                                                  fields,
                                                  toggleCreateDialog,
                                                  toggleEditDialog,
                                                  toggleViewDialog,
                                                  handleDeleteClick,
                                                  getFields,
                                                  selectedVisibleFeeOption,
                                                  translate
                                              }) {

    const hasItems = !!Object.values(fields).length
    const calculatedAmount = Number(Object.values(fields).reduce((memo, it) => {
        memo = memo + Number(it.AmountCalculated)
        return memo
    }, 0))

    let title = translate('text.trucks/drivers_fee');
    let buttonLabel =  translate('text.truck/driver_fee');
    let noDataText = translate('text.no_truck/driversFee')

    if (selectedVisibleFeeOption === 1) {
        title = translate('text.drivers_fee');
        buttonLabel = translate('btn.driver_fee');
        noDataText = translate('text.no_driversFee');
    } else if (selectedVisibleFeeOption === 2) {
        title = translate('text.trucks_fee');
        buttonLabel = translate('btn.truck_fee');
        noDataText = translate('text.no_trucksFee');
    }

    return (
        <React.Fragment>
            <div className="">
                <Card
                    addClass={classNames(
                        'col-span-2 xl:col-span-1 overflow-hidden min-h-[13rem] flex z-0 relative'
                    )}
                    addBodyClass={'border-0 border-l-4 border-red-700'}
                    bodyClass="flex flex-col w-full grow justify-center"
                >
                    {!!hasItems && (
                        <div className="px-4 py-3 flex justify-between items-center">
                            <h2 className="text-lg text-tm-gray-700 flex gap-2">
                                <ArrowTrendingDownIcon className="w-5 h-5 text-red-700"/>

                                {title}
                            </h2>

                            <button
                                className="btn btn-outline-secondary py-1"
                                onClick={toggleCreateDialog}
                            >
                                <PlusIcon className="w-5 h-5 mr-1"/>
                                {buttonLabel}
                            </button>
                        </div>
                    )}

                    <ResourceTable
                        data={fields}
                        fields={getFields()}
                        translate={translate}
                        isLoading={false}

                        options={{
                            style: {
                                verticalLines: true,
                                floatingActions: false,
                            },
                            classes: {
                                headerTextClass: 'text-xs font-semibold text-tm-gray-900 capitalize',
                                primaryTextClass: ''
                            }
                        }}

                        onRowClick={(it) =>  {
                            if (it.IsPayed) {
                                toggleViewDialog(it, it.dataIndex);
                            } else {
                                toggleEditDialog(it, it.dataIndex);
                            }
                        }}

                        actions={[
                            {
                                action: (it) => toggleViewDialog(it, it.dataIndex),
                                icon: EyeIcon,
                                visible: (it) => it.IsPayed,
                            },
                            {
                                action: (it) => toggleEditDialog(it, it.dataIndex),
                                icon: PencilIcon,
                                visible: (it) => !it.IsPayed,
                            },
                            {
                                action: (it) => handleDeleteClick(it, it.dataIndex),
                                icon: TrashIcon,
                                visible: (it) => !it.IsPayed,
                            }
                        ]}

                        tfoot={{
                            DriverID: () => <div className={"px-5"}>{translate('text.total')}</div>,
                            AmountCalculated: calculatedAmount
                        }}
                    />

                    <NoRecords
                        show={!hasItems}
                        onBtnClick={toggleCreateDialog}
                        addClass="px-4 py-10"
                        title={title}
                        text={noDataText}
                        btnLabel={buttonLabel}
                    />
                </Card>
            </div>
        </React.Fragment>
    )
}