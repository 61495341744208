import { classNames } from '../../../../common/util/util-helpers'
import PlusIcon from '@heroicons/react/20/solid/PlusIcon'
import React from 'react'
import { EyeIcon, PencilIcon, TrashIcon, ArrowTrendingDownIcon } from '@heroicons/react/24/outline'
import NoRecords from "../../../../common/components/no-records-found/no-records";
import ResourceTable from "../../../../common/components/resource-table";
import Card from "../../../../common/components/card";
import {excludeFields} from "../../../../common/util/util-fields";

export default function LoadPricingDriverAccessorials({
                                                          fields,
                                                          toggleCreateDialog,
                                                          toggleEditDialog,
                                                          handleDeleteClick,
                                                          toggleViewDialog,
                                                          getFields,
                                                          selectedVisibleFeeOption,
                                                          translate
                                                      }) {
    const hasItems = !!Object.values(fields).length

    const driverAccessorialCalculatedAmount = Number(Object.values(fields).reduce((memo, it) => {
        memo = memo + Number(it.AmountCalculated ?? 0)
        return memo
    }, 0));

    let title = translate('text.driver/truck_accessorials');
    let buttonLabel =  translate('btn.driver/truck_accessorial');
    let noDataText = translate('text.driver/truck_accessorial')

    if (selectedVisibleFeeOption === 1) {
        title = translate('text.driver_accessorials');
        buttonLabel = translate('btn.driver_accessorial');
        noDataText = translate('text.driver_accessorial');
    } else if (selectedVisibleFeeOption === 2) {
        title = translate('text.truck_accessorials');
        buttonLabel = translate('btn.truck_accessorial');
        noDataText = translate('text.truck_accessorial');
    }

    return (
        <div className="mt-4">
            <Card
                addClass={classNames(
                    'col-span-2 xl:col-span-1 min-h-[13rem] flex overflow-hidden z-0 relative'
                )}
                addBodyClass={'border-0 border-l-4 border-red-700'}
                bodyClass="flex flex-col w-full grow justify-center"
            >
                {!!hasItems && (
                    <div className="px-4 py-3 flex justify-between items-center">
                        <h2 className="text-lg text-tm-gray-700 flex gap-2">
                            <ArrowTrendingDownIcon className="w-5 h-5 text-red-700"/>

                            {title}
                        </h2>

                        <button
                            className="btn btn-outline-secondary py-1"
                            onClick={toggleCreateDialog}
                        >
                            <PlusIcon className="w-5 h-5 mr-1"/>
                            {buttonLabel}
                        </button>
                    </div>
                )}

                <ResourceTable
                    data={fields}
                    fields={excludeFields(getFields({}, true), ['AmountBalance', 'Deduction', 'DeductionExtra', 'Notes'])}
                    translate={translate}
                    isLoading={false}

                    options={{
                        style: {
                            verticalLines: true,
                            floatingActions: false,
                        },
                        classes: {
                            headerTextClass: 'text-xs font-semibold text-tm-gray-900 capitalize',
                            primaryTextClass: ''
                        }
                    }}

                    onRowClick={(it) =>  {
                        if (it.IsPayed) {
                            toggleViewDialog(it, it.dataIndex);
                        } else {
                            toggleEditDialog(it, it.dataIndex);
                        }
                    }}

                    actions={[
                        {
                            action: (it) => toggleViewDialog(it, it.dataIndex),
                            icon: EyeIcon,
                            visible: (it) => it.IsPayed,
                        },
                        {
                            action: (it) => toggleEditDialog(it, it.dataIndex),
                            icon: PencilIcon,
                            visible: (it) => !it.IsPayed,
                        },
                        {
                            action: (it) => handleDeleteClick(it, it.dataIndex),
                            icon: TrashIcon,
                            visible: (it) => !it.IsPayed,
                        }
                    ]}

                    tfoot={{
                        AccessorialID: () => <div className={"px-5"}>{translate('text.total')}</div>,
                        AmountCalculated: driverAccessorialCalculatedAmount
                    }}
                />

                <NoRecords
                    show={!hasItems}
                    onBtnClick={toggleCreateDialog}
                    addClass="px-4 py-10"
                    title={title}
                    text={noDataText}
                    btnLabel={buttonLabel}
                />
            </Card>
        </div>
    )
}