import React, {Component} from 'react'
import {connect} from 'react-redux'
import {createResource, deleteResource, getResource, updateResource} from '../../../data/actions/resource'
import LocalStorage from '../../../util/localStorage'
import Resources from '../../../data/services/resources'
import {showGlobalModal,} from '../../../data/actions/ui'
import {
    checkPerm,
    classNames,
    getDefaultTableOptions,
    getDefaultTableOptionsJSON,
    getProp,
    groupListBySCAC,
    mergeDeep,
    returnOffsetAndPagination,
    saveTableColumns,
    verifyFilterFields,
} from '../../../common/util/util-helpers'
import {Field, FieldsManager} from '../../../data/services/fields'
import {download} from '../../../data/actions/download'
import Tippy from '@tippyjs/react'
import {ArchiveBoxIcon, PencilIcon} from '@heroicons/react/24/outline'
import DocumentArrowDownIcon from '@heroicons/react/20/solid/DocumentArrowDownIcon'
import {
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DEFAULT_METADATA_SELECT_SEARCH_QUERY,
    DELETE_PERM,
    DRIVER_STATUS_TYPES,
    READ_PERM,
    UPDATE_PERM
} from '../../../util/util-constants'
import {currentDate} from '../../../common/util/util-dates'
import {cloneDeep} from '../../../common/util/util-vanilla'
import CellButtonWithCopy from "../../../common/components/resource-table/table-components/cell-button-copy";
import DriverStatusBadge from "../../../common/components/badge/driver-status-badge";
import Layout from "../../../common/components/layout";
import Page from "../../../common/components/layout/layout-components/page";
import PageHeader from "../../../common/components/layout/layout-components/page/page-header";
import PageHeaderInfo from "../../../common/components/layout/layout-components/page/page-header-info";
import ActiveFilters from "../../../common/components/resource-table/table-components/active-filters";
import TableCard from "../../../common/components/resource-table/table-components/table-card";
import TableSettingsPopOver from "../../../common/components/resource-table/table-components/table-settings-popover";
import TableFilters from "../../../common/components/resource-table/table-components/table-filters";
import TableCardFooter from "../../../common/components/resource-table/table-components/table-card-footer";
import Pagination from "../../../common/components/resource-table/table-components/pagination";
import TableBulkActions from "../../../common/components/resource-table/table-components/table-bulk-actions";
import NoRecordsTable from "../../../common/components/no-records-found/no-records-table";
import ResourceTable from "../../../common/components/resource-table";
import ModalConfirm from "../../../common/components/modal/modal-confirm";
import BulkBatchEdit from "../../../common/components/modal/bulk-batch-edit";
import TableOptionsDialog from "../../../common/components/resource-table/table-components/table-options-dialog";
import CreateUpdateUnitDialog from "../../../common/components/modal/unit-form-modal";

class DriversView extends Component {
    constructor(props) {
        super(props)
        this.pagePath = this.props.location.pathname.substring(1)
        this.tablePageDefaults = {
            behaviour: {
                rowSelect: checkPerm(Resources.DriverInfo, UPDATE_PERM),
                canAdjustWidth: true,
                hasMenu: true
            },
            style: {
                condensed: true,
                isGPUAccelerated: true
            }
        }

        this.state = {
            // Defaults and filters
            ...DEFAULT_CRUD_STATE,
            sortBy: 'Driver',
            fields: this.getFields(),
            tableOptions: getDefaultTableOptions(this.getFields(), this.tablePageDefaults, this.pagePath, this.props.translate),
            queryFilterFields: this.getQueryFilterFields(),
            tableColumns: {},
            prefilled: '',
            isColumnsDialogVisible: false,

            // Dialogs
            selectedItem: null,
            assignmentFields: this.getAssignmentFields(),
            assignmentDialog: false,
            assignmentDialogHideAnimation: false,
            confirmDialog: false,
            confirmDialogHideAnimation: false,
            selectedItems: {},
            selectedRows: {},
            batchActionDialog: false,
            batchFields: this.getBatchFields(),
            breakpoint: {},
            isTableFullScreen: false
        }

        this.state = mergeDeep(this.state, verifyFilterFields(
            LocalStorage.rehydrateState(this.pagePath),
            this.state.queryFilterFields
        ));
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.sort !== this.state.sort) || (prevState.myDispatchGroup !== this.state.myDispatchGroup) || (prevState.active !== this.state.active) || (prevState.limit !== this.state.limit) || (prevState.sortBy !== this.state.sortBy && this.state.sortBy !== '')) {
            this.fetchData()
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: this.getQuery()
        }))
    }

    downloadExcel = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                    format: 'EXCEL',
                    name: 'drivers_' + currentDate() + '.xlsx'
                },
                this.getQuery(),
            )
        }))
    }

    downloadExcelTemplate = () => {
        this.props.dispatch(download({
            user: LocalStorage.get('user'),
            resource: this.getResource(),
            query: Object.assign({
                format: 'EXCEL',
                name: 'drivers_template' + currentDate() + '.xlsx'
            }, this.getQuery(), {excelTemplate: 1})
        }))
    }

    archiveResource = (item) => {
        this.setState({text: `${this.props.translate("message.confirm_archive_generic")} ${item.FirstName} ${item.LastName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({
                    ...returnOffsetAndPagination(getProp(this.props.resource.data, 'list', []), this.getQuery(), this.state.paginationPage),
                    confirmDialog: false
                }, () => {
                    this.props.dispatch(deleteResource({
                        user: LocalStorage.get('user'),
                        query: Object.assign({}, this.getQuery(), {
                            DriverID: item[this.getPrimaryKey()]
                        }),
                        errorMessage: true,
                        successMessage: this.props.translate(`message.archived_generic`, [`${item.FirstName} ${item.LastName}`]),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    restoreFromArchive = (item) => {
        this.setState({text: `${this.props.translate("message.are_you_sure_restore_driver")} ${item.FirstName} ${item.LastName}?`}, () => {
            this.handleToggleConfirmDialog(() => {
                this.setState({confirmDialog: false}, () => {
                    this.props.dispatch(updateResource({
                        user: LocalStorage.get('user'),
                        params: {
                            id: item[this.getPrimaryKey()],
                            ArchivedDate: 1
                        },
                        errorMessage: true, successMessage: `Driver ${item.FirstName} ${item.LastName} restored.`,
                        query: this.getQuery(),
                        resource: this.getResource(),
                        piggyResource: this.getResource()
                    }))
                })
            }, item)
        })
    }

    handleBulkEdit = (fields) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get('user'),
            params: {
                IDs: Object.keys(this.state.selectedRows),
                Fields: FieldsManager.getFieldKeyValues(fields)
            },
            resource: Resources.DriversBulkEdit,
            query: this.getQuery(),
            piggyResource: this.getResource()
        }))
        this.handleCloseBatchActionDialog()
        this.handleResetBatchFields()
    }

    /** UI Events
     ================================================================= */
    handleBreakpointChange = (breakpoint) => {
        this.setState({
            breakpoint
        }, () => {
            if (this.state.breakpoint.index <= 1 && this.state.queryFilterFields.limit.value !== 10) {
                this.handleFilterInputChange('limit', 10);
            }
        });
    }
    handleUpdateSort = (sortBy) => {
        let sort = (this.state.sortBy === sortBy) ? (this.state.sort === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
        if (!sortBy) {
            sortBy = 'Driver'
        }
        this.setState({
            sortBy: sortBy,
            sort: sort
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleUpdateOffset = (offset, pagenum) => {
        this.setState({
            offset: offset,
            paginationPage: pagenum
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleQuickView = (item) => {
        this.props.dispatch(showGlobalModal('ViewDriverCard', item.DriverID))
    }

    handleAssignment = (item) => {
        let tmp = this.getAssignmentFields()
        Object.keys(tmp).forEach(it => {
            tmp[it].value = item[it] ? {
                value: item[it],
                label: item[`${it.replace('ID', '')}`]
            } : ''
        })
        this.setState({assignmentFields: tmp, selectedItem: item, assignmentDialog: true})
    }

    handleToggleConfirmDialog = (submit) => {
        this.setState({
            confirmModal: submit,
            confirmDialog: !this.state.confirmDialog
        })
    }

    handleCloseAssignmentsDialog = () => {
        this.setState({selectedItem: null, assignmentDialog: false})
    }

    handleClearFiltersClick = (excludeAdditional = []) => {
        const queryFilterFields = cloneDeep(this.state.queryFilterFields)
        const defaultExcludedFields = ['limit']
        const excludedFields = defaultExcludedFields.concat(excludeAdditional)

        Object.values(queryFilterFields).filter(it => !excludedFields.includes(it.name)).forEach(it => {
            FieldsManager.updateField(queryFilterFields, it.name, '')
        })

        this.setState({
            queryFilterFields: queryFilterFields,
            offset: 0,
            paginationPage: 1
        }, () => {
            this.saveFilters()
            this.fetchData()
        })
    }

    handleFilterInputChange = (name, value) => {
        if (name === 'radius' && value === true) {
            this.handleToggleLocationRadiusSearchDialog()
        } else {
            let fields = this.state.queryFilterFields
            if (name === 'PreferenceTypeID') {
                fields = FieldsManager.updateField(fields, 'PreferenceTypeSubItem', '')
            }
            fields = FieldsManager.updateField(fields, name, value)
            this.setState({
                queryFilterFields: fields,
                offset: 0,
                paginationPage: 1
            }, () => {
                this.saveFilters()
                this.fetchData()
            })
        }
    }

    handleToggleLocationRadiusSearchDialog = () => {
        this.setState({LocationRadiusSearchDialog: !this.state.LocationRadiusSearchDialog})
    }

    handleLinkClick = (link) => {
        this.props.history.push(link)
    }

    handleActionUpdate = item => {
        this.handleLinkClick(`/drivers/info/${item[this.getPrimaryKey()]}`)
    }

    handleOnLoadClick = item => {
        item.OnLoad && this.handleLinkClick(`/loads/info/${item.OnLoad}`)
    }

    handleCloseBatchActionDialog = () => {
        this.setState({batchActionDialog: false})
    }

    handleResetBatchFields = () => {
        this.setState({
            selectedItems: {},
            selectedRows: {},
            batchFields: this.getBatchFields()
        })
    }

    handleCreateUpdateResource = () => {
        this.handleLinkClick(`/driver/create`)
    }

    handleSelectRowClick = (item, event) => {
        const itemID = item[this.getPrimaryKey()];

        const data = getProp(this.props.resource.data, 'list', [])
        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems
        if (event?.nativeEvent?.shiftKey) {
            const firstSelectedItem = data.findIndex(it => selectedRows[it[this.getPrimaryKey()]])
            const lastSelectedItem = data.findIndex(it => it[this.getPrimaryKey()] === itemID)

            if (firstSelectedItem > -1 && lastSelectedItem > -1) {
                if (firstSelectedItem < lastSelectedItem) {
                    for (let i = firstSelectedItem; i < lastSelectedItem + 1; i++) {
                        Object.assign(selectedRows, {[data[i][this.getPrimaryKey()]]: data[i][this.getPrimaryKey()]})
                        selectedItems[data[i][this.getPrimaryKey()]] = data[i]
                    }
                } else {
                    for (let i = lastSelectedItem; i < firstSelectedItem + 1; i++) {
                        selectedItems[data[i][this.getPrimaryKey()]] = data[i]
                        Object.assign(selectedRows, {[data[i][this.getPrimaryKey()]]: data[i][this.getPrimaryKey()]})
                    }
                }
            }
        } else {
            if (selectedRows[itemID]) {
                delete selectedItems[itemID]
                delete selectedRows[itemID]
            } else {
                selectedItems[itemID] = data.find(it => it[this.getPrimaryKey()] === itemID)
                Object.assign(selectedRows, {[itemID]: item})
            }
        }

        this.setState({selectedRows, selectedItems})
    }

    handleSelectAllClick = (selectAll) => {
        const data = cloneDeep(getProp(this.props.resource.data, 'list', []))

        let selectedRows = cloneDeep(this.state.selectedRows)
        let selectedItems = this.state.selectedItems

        if (!selectAll) {
            Object.assign(selectedRows, data.reduce((memo, it) => {
                selectedItems[it[this.getPrimaryKey()]] = it
                memo[it[this.getPrimaryKey()]] = it
                return memo
            }, {}))
        } else {
            let selectedRowsKeys = Object.keys(selectedRows)
            data.forEach(it => {
                if (selectedRowsKeys.includes(it[this.getPrimaryKey()].toString())) {
                    delete selectedItems[it[this.getPrimaryKey()]]
                    delete selectedRows[it[this.getPrimaryKey()]]
                }
            })
        }

        this.setState({selectedRows})
    }

    handleToggleColumnSettingsDialog = () => {
        this.setState({
            isColumnsDialogVisible: !this.state.isColumnsDialogVisible
        })
    }

    handleSetTableColumnOptions = (columns) => {
        let tableOptions = cloneDeep(this.state.tableOptions)

        tableOptions.columns = columns.reduce((memo, it) => {
            memo[it.name] = it
            return memo
        }, {})

        this.setState({
            tableOptions,
            isColumnsDialogVisible: false
        }, () => saveTableColumns(this.pagePath, tableOptions))
    }

    getDefaultTableColumnOptions = () => {
        return getDefaultTableOptionsJSON(this.getFields(), this.tablePageDefaults, this.props.translate);
    }

    setOptions = (options) => {
        this.setState({
            tableOptions: options
        }, () => saveTableColumns(this.pagePath, options))
    }

    handleFullScreenToggle = () => {
        this.setState({
            isTableFullScreen: !this.state.isTableFullScreen
        })
    }

    setSelectedRows = (selectedRows) => {
        this.setState({selectedRows})
    }
    /** Helpers
     ================================================================= */
    saveFilters = () => {
        LocalStorage.persistState(this.pagePath, this.state, ['sortBy', 'sort', 'offset', 'paginationPage', 'limit', 'query', 'archived', 'fields'])
    }

    getFields = () => ({
        FirstName: new Field('FirstName', '', ['empty'], false, 'text'),
        LastName: new Field('LastName', '', ['empty'], false, 'text'),
        Email: new Field('Email', '', ['empty'], false, 'custom', {
            render: (it) => <CellButtonWithCopy copyText={it.Email} translate={this.props.translate}>
                {it.Email}
            </CellButtonWithCopy>
        }),
        StatusID: new Field('StatusID', '', [''], false, 'custom', {
            render: (item) => <DriverStatusBadge item={item} translate={this.props.translate}/>
        }),
        DriverIDNumber: new Field('DriverIDNumber', '', ['']),
        Phone: new Field('Phone', '', ['empty'], false, 'text'),
        IsPaysIFTA: new Field('IsPaysIFTA', '', [''], false, 'checkbox'),
        IsPaysFuel: new Field('IsPaysFuel', '', [''], false, 'checkbox'),
        DriverPositionTypeID: new Field('DriverPositionTypeID', '', [], false, 'select'),
        PayToID: new Field('PayToID', '', [], false, 'select-search'),

        CreateUpdateDate: new Field('CreateUpdateDate', '', ['empty'], false, 'date')
    })

    getAssignmentFields = () => ({
        DriverID: new Field('DriverID', '', ['empty_select_search'], true, 'select-search', {addContainerClass: 'col-span-6'}),
        CoDriverID: new Field('CoDriverID', '', [], false, 'select-search', {addContainerClass: 'col-span-6'}, {
            isClearable: true
        }),
        TruckID: new Field('TruckID', '', ['empty_select_search'], false, 'select-search', {addContainerClass: 'col-span-6'}),
        TrailerID: new Field('TrailerID', '', ['empty_select_search'], false, 'select-search', {addContainerClass: 'col-span-6'})
    })

    getBatchFields = () => {
        return {
            DriverTypeID: new Field('DriverTypeID', '', [''], false, 'select', {
                label: 'CurrentEquipment'
            }),
            DriverPositionTypeID: new Field('DriverPositionTypeID', '', [''], false, 'select'),
            Notes: new Field('Notes', '', []),
        }
    }

    getQuery = () => {
        const {Active, State} = FieldsManager.getFieldKeyValues(this.state.queryFilterFields)
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            offset: this.state.offset,
            // ...this.state.queryFilterFields.radius.value,
            ...FieldsManager.getFieldKeyValues(this.state.queryFilterFields),
            searchFields: JSON.stringify({
                Active: Active,
                StateID: State.join(',')
            })
        }
    }

    getQueryFilterFields = () => {
        return {
            query: new Field('query', '', [''], false, 'search', {
                containerClass: 'col-md-3 col-6'
            }, {}),
            StatusIDs: new Field('StatusIDs', '', [''], false, 'multi-select', {}, {
                isMulti: true,
                all: true
            }),
            DriverEndorsementType: new Field('DriverEndorsementType', '', [''], false, 'multi-select'),
            DriverPositionTypeID: new Field('DriverPositionTypeID', '', [], false, 'select'),
            PreferenceTypeID: new Field('PreferenceTypeID', '', [''], false, 'select'),
            PreferenceTypeSubItem: new Field('PreferenceTypeSubItem', '', [''], false, 'multi-select'),
            State: new Field('State', '', [''], false, 'multi-select', {}, {menuPlacement: "top"}),
            PayToID: new Field('PayToID', '', [''], false, 'select-search', {}, {menuPlacement: "top"}),
            HasActiveEmployment: new Field('HasActiveEmployment', '1', [''], false, 'checkbox'),
            Phone: new Field('Phone', '', [''], false, 'text'),
            OfficeID: new Field('OfficeID', '', [''], false, 'select-search', {}, {menuPlacement: "top"}),
            archived: new Field('archived', '', [''], false, 'checkbox'),
            limit: new Field('limit', 10, [''], false, 'select', {hideLabel: true}, {
                menuPlacement: "top",
                hasPortal: true
            })
        }
    }

    getResource = () => {
        return Resources.Drivers
    }

    getPrimaryKey = () => {
        return 'DriverID'
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, resource} = this.props

        const data = getProp(resource.data, 'list', [])
        const count = getProp(resource.data, 'count', 0)
        const areSomeItemsSelected = !!Object.keys(this.state.selectedRows).length
        const isLoading = !!resource.isLoading;

        return (
            <Layout
                onBreakpointChange={this.handleBreakpointChange}
                isAccessible={!(resource.errorStatus === 2)}
                {...this.props}
            >
                <Page>
                    <PageHeader
                        title={translate('page.heading.drivers')}
                        buttonLabel={translate('btn.create_new')}
                        onButtonClick={() => this.handleCreateUpdateResource()}
                        hasPerm={checkPerm(this.getResource(), CREATE_PERM)}
                        afterTitle={(
                            <PageHeaderInfo
                                dispatch={this.props.dispatch}
                            />
                        )}
                    />

                    <div className="sm:flex">
                        <ActiveFilters
                            filterFields={this.state.queryFilterFields}
                            onLabelClick={this.handleFilterInputChange}
                            onClearFiltersClick={this.handleClearFiltersClick}
                            translate={translate}
                        />

                        <div className="ml-auto flex sm:justify-start justify-end items-center">
                            {/*<Tippy content={translate('text.download_template')}>*/}
                            {/*    <button*/}
                            {/*        className="btn-icon"*/}
                            {/*        onClick={this.downloadExcelTemplate}*/}
                            {/*    >*/}
                            {/*        <RectangleGroupIcon className="w-5 h-5"/>*/}
                            {/*    </button>*/}
                            {/*</Tippy>*/}

                            <Tippy content={translate('text.download_excel')}>
                                <button
                                    className="btn-icon"
                                    onClick={this.downloadExcel}
                                >
                                    <DocumentArrowDownIcon className="w-5 h-5"/>
                                </button>
                            </Tippy>

                            <TableSettingsPopOver
                                options={this.state.tableOptions}
                                setOptions={this.setOptions}
                                toggleColumnSettingsDialog={this.handleToggleColumnSettingsDialog}
                                translate={translate}
                            />
                        </div>
                    </div>

                    <TableCard
                        addClass={
                            classNames(
                                this.state.isTableFullScreen
                                    ? 'fixed inset-1 -top-1 z-50'
                                    : undefined
                            )}
                    >
                        <TableFilters
                            hideLimit
                            filterFields={this.state.queryFilterFields}
                            handleInputChange={this.handleFilterInputChange}
                            translate={translate}
                            onRefreshTable={this.fetchData}
                            onFullScreenToggle={this.handleFullScreenToggle}
                            isTableFullScreen={this.state.isTableFullScreen}
                            isLoading={isLoading}
                            selects={{
                                StatusIDs: DRIVER_STATUS_TYPES,
                                PayToID: {
                                    api: 'api/' + Resources.OrganizationsQuick,
                                    query: DEFAULT_METADATA_SELECT_SEARCH_QUERY(),
                                    searchMap: (item) => ({
                                        value: item.OrganizationID,
                                        label: item.LegalName
                                    })
                                }
                            }}
                        />

                        <ResourceTable
                            data={data}
                            fields={this.getFields()}
                            verticalTableIsVisible={this.state.breakpoint.index <= 1}

                            translate={translate}
                            isLoading={resource.isLoading}

                            options={this.state.tableOptions}

                            limit={this.state.queryFilterFields.limit.value}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            saveTableOptions={this.setOptions}

                            onRowClick={checkPerm(Resources.DriverInfo, UPDATE_PERM) ? this.handleActionUpdate : null}

                            onView={this.handleQuickView}
                            onEdit={this.handleActionUpdate}
                            onRestore={this.restoreFromArchive}

                            hasViewPerm={checkPerm(this.getResource(), READ_PERM)}
                            hasEditPerm={checkPerm(Resources.DriverInfo, UPDATE_PERM)}
                            hasRestorePerm={checkPerm(this.getResource(), UPDATE_PERM)}

                            actions={[
                                {
                                    action: (it) => {(it.StatusID >= 5) && this.archiveResource(it)},
                                    icon: ArchiveBoxIcon,
                                    visible: (it) => checkPerm(this.getResource(), DELETE_PERM) && !it.ArchivedDate,
                                    label: false,
                                    order: 30,
                                    disabled: false,
                                    title: (it) => {return it.StatusID >= 5 ? translate("btn.archive") : translate("Active asset can't be deactivated")},
                                    class: false,
                                    iconClass: (it) => {return it.StatusID >= 5 ? "w-5 h-5": "w-5 h-5 opacity-30"}

                                }
                            ]}
                            onSelectRow={this.handleSelectRowClick}
                            selectedRows={this.state.selectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                            tableKey={this.getPrimaryKey()}
                        />

                        {/*Table footer*/}
                        <TableCardFooter
                            show={!(!data.length && !resource.isLoading)}
                        >
                            <Pagination
                                count={count}
                                isLoading={resource.isLoading}
                                hideRowsPerPage={this.state.breakpoint.index <= 1}
                                handleQueryChange={
                                    (name, value, currentPage) => name === "offset"
                                        ? this.handleUpdateOffset(value, currentPage)
                                        : this.handleFilterInputChange(name, value)
                                }
                                pageOffset={this.state.offset}
                                queryFields={this.state.queryFilterFields}
                                translate={translate}
                            />
                        </TableCardFooter>

                        <TableBulkActions
                            selectedRows={this.state.selectedRows}
                            tableKey={this.getPrimaryKey()}
                            fields={this.getFields()}
                            translate={translate}
                            options={this.state.tableOptions}
                            setSelectedRows={this.setSelectedRows}
                            onSelectAllClick={this.handleSelectAllClick}
                        >
                            <div className="flex divide-x-2 divide-tm-gray-300">
                                <div className="flex space-x-2 px-3">
                                    {checkPerm(Resources.DriverInfo, UPDATE_PERM) && (
                                        <Tippy content={translate('text.bulk_actions')}>
                                            <button
                                                onClick={() => this.setState({batchActionDialog: true})}
                                                disabled={!areSomeItemsSelected}
                                                className={classNames(areSomeItemsSelected ? 'hover:bg-primary-shade' : undefined, 'icon-btn p-2 flex items-center')}
                                            >
                                                <PencilIcon
                                                    className={classNames(areSomeItemsSelected ? 'text-primary-contrast' : 'text-tm-gray-400', 'w-5 h-5')}/>
                                            </button>
                                        </Tippy>
                                    )}
                                </div>
                            </div>
                        </TableBulkActions>

                        <NoRecordsTable
                            show={(data.length === 0) && !resource.isLoading}
                            canCreate={checkPerm(this.getResource(), CREATE_PERM)}
                            title={'No matching records found'}
                            text={'Create a new driver'}
                            btnLabel="Create driver"
                            onBtnClick={() => {
                                this.setState({prefilled: this.state.queryFilterFields.query.value}, () => {
                                    this.handleLinkClick(`/driver/create?prefilled=` + this.state.prefilled)
                                })
                            }}
                            onClearFilterClick={this.handleClearFiltersClick}
                            clearFilterBtnLabel={translate('text.clear_all_filters')}
                            clearFilterText={translate('text.try_without_filters')}
                            filters={this.state.queryFilterFields}
                        />
                    </TableCard>

                    <CreateUpdateUnitDialog
                        {...this.props}
                        title={'UpdateUnit'}
                        show={this.state.assignmentDialog}
                        item={this.state.selectedItem}
                        translate={translate}

                        disableLocationEdit

                        fetchByKey={'DriverID'}
                        fetchByLabel={'Contact'}
                        fetchByValue={this.state.selectedItem?.DriverID}

                        resourceName={Resources.Units}
                        piggyResourceName={this.getResource()}

                        query={this.getQuery()}
                        fields={this.getAssignmentFields()}

                        onSubmit={(params) => {
                            this.props.dispatch(createResource({
                                user: LocalStorage.get('user'),
                                params: params,
                                query: this.getQuery(),
                                errorMessage: true, successMessage: 'Unit updated',
                                resource: Resources.Units,
                                piggyResource: this.getResource(),
                            }))
                            this.handleCloseAssignmentsDialog()
                        }}

                        onClose={this.handleCloseAssignmentsDialog}
                        selects={{
                            DriverID: {
                                api: 'api/' + Resources.DriversQuick,
                                query: {},
                                searchMap: (item) => ({
                                    value: item.DriverID,
                                    label: item.FirstName + ' ' + item.LastName
                                })
                            },
                            CoDriverID: {
                                api: 'api/' + Resources.DriversQuick,
                                query: {NotOnLoadInAction: 1},
                                searchMap: (item) => ({
                                    value: item.DriverID,
                                    label: item.FirstName + ' ' + item.LastName
                                })
                            },
                            TruckID: {
                                api: 'api/' + Resources.TrucksQuick,
                                query: {NotOnLoadInAction: 1},
                                customizeList: (list) => {
                                    return groupListBySCAC(list, 'Truck')
                                }
                            },
                            TrailerID: {
                                api: 'api/' + Resources.TrailersQuick,
                                query: {NotOnLoadInAction: 1},
                                customizeList: (list) => {
                                    return groupListBySCAC(list, 'Trailer')
                                }
                            }
                        }}
                    />

                    <ModalConfirm
                        title={'Confirm'}
                        show={!!this.state.confirmDialog}
                        text={this.state.text}
                        onClose={() => this.setState({confirmDialog: false})}
                        buttonLabel={translate('btn.confirm')}
                        closeButtonLabel={'Cancel'}
                        translate={translate}
                        onConfirm={this.state.confirmModal}
                    />

                    {this.state.batchActionDialog && (
                        <BulkBatchEdit
                            show={this.state.batchActionDialog}
                            fields={this.state.batchFields}
                            translate={translate}
                            onClose={this.handleCloseBatchActionDialog}
                            selectedItems={Object.values(this.state.selectedItems)}
                            itemKeyLabel={'Contact'}
                            onSubmit={this.handleBulkEdit}
                        />
                    )}

                    <TableOptionsDialog
                        show={this.state.isColumnsDialogVisible}
                        pagePath={this.pagePath}
                        columns={this.state.tableOptions.columns}
                        setTableColumnOptions={this.handleSetTableColumnOptions}
                        getDefaultTableColumnOptions={this.getDefaultTableColumnOptions}
                        onClose={this.handleToggleColumnSettingsDialog}
                        translate={translate}
                    />

                    {/*{this.state.LocationRadiusSearchDialog && (*/}
                    {/*    <LocationRadiusSearch*/}
                    {/*        handleSearchByRadius={this.handleFilterInputChange}*/}
                    {/*        {...this.props}*/}
                    {/*        close={this.handleToggleLocationRadiusSearchDialog}*/}
                    {/*        radius={this.state.queryFilterFields.radius.value}*/}
                    {/*    />*/}
                    {/*)}*/}
                </Page>
            </Layout>
        )
    }
}

export default connect(state => state)(DriversView)
