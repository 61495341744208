import SocketAPI from "./../../common/package/notification/socket";
import {
    editedLoad,
    joinedLoad,
    leftLoad,
    LoadExternalMessages,
    loadMessage,
    loadUpdated,
    newCustomerLoadMessage, newDriverLoadMessage,
    newLoadEvent,
    newUserLoadMessage
} from './actions/realtime'
import moment from 'moment'

const socket = new SocketAPI();

const OnLoadUpdatedEvent = "OnLoadUpdated";
const OnJoinedLoadEvent = "OnJoinedLoad";
const OnLeftLoadEvent = "OnLeftLoad";
const OnEditingLoadEvent = "OnEditingLoad";
const OnEditedLoadEvent = "OnEditedLoad";
const OnCancelEditingLoadEvent = "OnCancelEditingLoad";

const OnUserLoadMessage = "OnUserLoadMessage";
const OnCustomerLoadMessage = "OnCustomerLoadMessage";
const OnUserDriverMessage = "OnUserDriverMessage";
const OnLoadMessages = "OnLoadMessages";
const OnLoadEvent = "OnLoadEvent";

const OnExternalCustomerJoinedLoad = "OnExternalCustomerJoinedLoad";
const OnExternalCustomerLoadMessage = "OnExternalCustomerLoadMessage";
const OnExternalLoadMessages = "OnExternalLoadMessages";

const realtimeMiddleware = store => next => action => {

    switch (action.type) {
        case 'DONE_LOGIN':
            connect(socket, store)
            break;
        case 'LOGOUT':
            disconnect(socket, store)
            break;
        case 'JOIN_LOAD':
            socket.emit(OnJoinedLoadEvent, {
                LoadID: action.data.LoadID
            });
            break;
        case 'LEAVE_LOAD':
            socket.emit(OnLeftLoadEvent, {
                LoadID: action.data.LoadID
            });
            break;
        case 'EDIT_LOAD':
            socket.emit(OnEditingLoadEvent, {
                LoadID: action.data.LoadID
            });
            break;

        case 'CANCEL_LOAD':
            socket.emit(OnCancelEditingLoadEvent, {
                LoadID: action.data.LoadID
            });
            break;
        case 'LOAD_SEND_MESSAGE':
            socket.emit(OnUserLoadMessage, {
                Mention: action.data?.Mention ?? [],
                Message: action.data.Message,
                LoadID: action.data.LoadID,
                DateTime: moment().utc().format("YYYY-MM-DD HH:mm:ss")
            });
            break;
        case 'LOAD_SEND_CUSTOMER_MESSAGE':
            socket.emit(OnCustomerLoadMessage, {
                Message: action.data.Message,
                LoadID: action.data.LoadID,
                DateTime: moment().utc().format("YYYY-MM-DD HH:mm:ss")
            });
            break;
        case 'LOAD_SEND_DRIVER_MESSAGE':
            socket.emit(OnUserDriverMessage, {
                Message: action.data.Message,
                LoadID: action.data.LoadID,
                DateTime: moment().utc().format("YYYY-MM-DD HH:mm:ss")
            });
            break;
        case 'LOAD_EXTERNAL_SEND_CUSTOMER_MESSAGE':
            // TODO Add as dispatch action
            // Dispatch when external customer is on share page and then sends a message via chat
            socket.emit(OnExternalCustomerLoadMessage, {
                Message: action.data.Message,
                Name: action.data.Name,
                ExternalAccessToken: action.data.ExternalAccessToken,
                DateTime: moment().utc().format("YYYY-MM-DD HH:mm:ss")
            });
            break;
        case 'LOAD_EXTERNAL_JOINED_LOAD':
            // TODO Add as dispatch action,
            // dispatch when external customer lands on share page and chat is enabled
            socket.emit(OnExternalCustomerJoinedLoad, {
                ExternalAccessToken: action.data.ExternalAccessToken,
                Name: action.data.Name,
                DateTime: moment().utc().format("YYYY-MM-DD HH:mm:ss")
            });
            break;
        default:
            break;
    }

    return next(action);
};

realtimeMiddleware.getUrl = () => {
    return null;
}

realtimeMiddleware.getToken = () => {
    return null;
}

realtimeMiddleware.run = (store, getUrl, getToken) => {
    realtimeMiddleware.getUrl = getUrl;
    realtimeMiddleware.getToken = getToken;

    connect(socket, store);
}

const connect = (socket, store) => {

    if (realtimeMiddleware.getToken()) {
        socket.connect(realtimeMiddleware.getUrl(), realtimeMiddleware.getToken())
            .then(() => {
                console.log("realtimeMiddleware:connect")

            })
            .catch((e) => {
                console.log("realtimeMiddleware:error", e)
            });
    } else {
        socket.connect(realtimeMiddleware.getUrl())
            .then(() => {
                console.log("realtimeMiddleware:connect")

            })
            .catch((e) => {
                console.log("realtimeMiddleware:error", e)
            });
    }

    socket.on(OnJoinedLoadEvent, (data) => {
        console.log(OnJoinedLoadEvent, data);
        store.dispatch(joinedLoad(data))
    });
    socket.on(OnLeftLoadEvent, (data) => {
        console.log(OnLeftLoadEvent, data);
        store.dispatch(leftLoad(data))
    });
    socket.on(OnLoadUpdatedEvent, (data) => {
        console.log(OnLoadUpdatedEvent, data);
        store.dispatch(loadUpdated(data))
    });
    socket.on(OnEditedLoadEvent, (data) => {
        console.log(OnEditingLoadEvent, data);
        store.dispatch(editedLoad(data))
    });
    // When logged-in user connects on internal chat for the first time list of all messages is returner
    // Note this returns both internal and customer messages (small optimization)
    socket.on(OnLoadMessages, (data) => {
        console.log(OnLoadMessages, data);
        store.dispatch(loadMessage(data))
    });

    // When logged-in user receives message on internal chat from another user
    socket.on(OnUserLoadMessage, (data) => {
        console.log(OnUserLoadMessage, data);
        store.dispatch(newUserLoadMessage(data))
    });

    // When logged-in or external user send message to customer chat
    socket.on(OnCustomerLoadMessage, (data) => {
        console.log(OnCustomerLoadMessage, data);
        store.dispatch(newCustomerLoadMessage(data))
    });

    // Received when OnExternalCustomerJoinedLoad is sent as a response with the initial chat state (all messages)
    socket.on(OnExternalLoadMessages, (data) => {
        store.dispatch(LoadExternalMessages(data))
    });

    // Load new event
    socket.on(OnLoadEvent, (data) => {
        store.dispatch(newLoadEvent(data))
    });

    socket.on(OnUserDriverMessage, (data) => {
        store.dispatch(newDriverLoadMessage(data))
    });
}

const disconnect = (socket) => {
    socket.disconnect()
        .then(() => {
            console.log("notificationMiddleware:disconnect");
        })
        .catch((e) => {
            console.log("notificationMiddleware:error", e)
        });
}

export default realtimeMiddleware